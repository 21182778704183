import {Injectable} from '@angular/core'
import {Loan, Property, Child, Applicant, Income, Kalpylator, Parameters, Car} from '@sparbanken-syd/kalpylator'
import {BehaviorSubject, Observable, Subject, merge} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class KalpService {

  /**
   * We store the last step here, if the user
   * was in step 6 when asking for a KALP we can
   * remember that for them. Basically just reusing this
   * service for whatever.
   */
  public lastStep = 0

  /**
   * Below subjects are for sending data to us.
   */
  public applicants$: BehaviorSubject<Applicant[]> = new BehaviorSubject<Applicant[]>([])

  public children$: BehaviorSubject<Child[]> = new BehaviorSubject<Child[]>([])

  public incomes$: BehaviorSubject<Income[]> = new BehaviorSubject<Income[]>([])

  public loans$: BehaviorSubject<Loan[]> = new BehaviorSubject<Loan[]>([])

  public properties$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>([])

  public cars$: BehaviorSubject<Car[]> = new BehaviorSubject<Car[]>([])

  public changes$: Observable<any>

  public applicantCount$: Observable<number>

  public calculationsPossible$: Observable<boolean>

  /**
   * Help innocent bystanders know if we have calculated
   * something. Loads of rows for no real value?
   */
  private pCalculationsPossible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private applicantCount: BehaviorSubject<number> = new BehaviorSubject<number>(1)

  private borgo: Parameters = {
    domain: 'borgo',
    defaultTaxTable: 34,
    livingExpenseDeduction: [
      {class: ['EMPLOYED', 'EMPLOYED'], deduction: 2600},
      {class: ['EMPLOYED', 'RETIRED'], deduction: 2450},
      {class: ['RETIRED', 'RETIRED'], deduction: 2300}
    ],
    livingExpenses: [
      {age: 6, expense: 3200},
      {age: 13, expense: 3750},
      {age: 18, expense: 4850},
      {age: 65, expense: 9100},
      {age: 200, expense: 7950}
    ],
    cityDwellerFactor: 1.05,
    almostRetiredThreshold: 60, // Borgo, 62 for bank
    almostRetiredLivingExpense: 7951, // Borgo + 1, 9100 for bank, so that we know if this kicks in
    almostRetiredIncomeFactor: 0.7,
    childrenBenefit: 1250,
    childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
    loanDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MORTGAGE: {
        interest: 0.05,
        mortgagePercent: 0.05
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BLANCO: {
        interest: 0.9,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      CREDIT: {
        interest: 0.1,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BORGEN: {
        interest: 0.1,
        mortgagePercent: 0.1
      }
    },
    propertyDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      VILLA: {
        runCost: 4500,
        yearlyTax: 8874
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      HYRES: {
        runCost: 200,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BOSTADSRATT: {
        runCost: 200,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FRITIDSHUS: {
        runCost: 1500,
        yearlyTax: 8874
      }
    },
    carDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LEASE: {
        cost: 4700 // Borgo cost
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      OWN: {
        cost: 2500 // Borgo
      }
    }
  }

  private sparbanken: Parameters = {
    defaultTaxTable: 33,
    domain: 'default',
    livingExpenseDeduction: [
      {class: ['EMPLOYED', 'EMPLOYED'], deduction: 800},
      {class: ['EMPLOYED', 'RETIRED'], deduction: 1400},
      {class: ['RETIRED', 'RETIRED'], deduction: 2000}
    ],
    livingExpenses: [
      {age: 18, expense: 3800},
      {age: 64, expense: 9700},
      {age: 65, expense: 7600},
      {age: 200, expense: 7600}
    ],
    cityDwellerFactor: 1,
    almostRetiredThreshold: 62,
    almostRetiredLivingExpense: 9100,
    almostRetiredIncomeFactor: 0.7,
    childrenBenefit: 1250, // As of 20-22
    childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
    loanDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MORTGAGE: {
        interest: 0.05,
        mortgagePercent: 0.05
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BLANCO: {
        interest: 0.9,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      CREDIT: {
        interest: 0.1,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BORGEN: {
        interest: 0.1,
        mortgagePercent: 0.1
      }
    },
    propertyDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      VILLA: {
        runCost: 4500,
        yearlyTax: 8874
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      HYRES: {
        runCost: 200,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BOSTADSRATT: {
        runCost: 200,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FRITIDSHUS: {
        runCost: 1500,
        yearlyTax: 8874
      }
    },
    carDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LEASE: {
        cost: 2000 // Standard SPB
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      OWN: {
        cost: 2000 // Standard SPB
      }
    }
  }

  private readonly propertyMap: { [key: string]: Parameters } = {}

  private readonly inputs: Subject<any>[]

  constructor() {
    this.calculationsPossible$ = this.pCalculationsPossible$.asObservable()

    this.propertyMap = {
      borgo: this.borgo,
      sparbanken: this.sparbanken
    }

    // All our inputs if someone cares.
    this.inputs = [this.applicants$, this.children$, this.incomes$, this.loans$, this.properties$, this.cars$]
    this.changes$ = merge(...this.inputs)

    // Get and emit number of applicants
    this.applicantCount$ = this.applicantCount.asObservable()
    this.applicants$.subscribe({
      next: (a: Applicant[]) => this.applicantCount.next(a.length)
    })
  }

  public getKalpylator(domain: string): Kalpylator {
    // Yes we have enough data? This is in the wrong place, but
    // if someone asks for a Kalpylator then we are good to go.
    this.pCalculationsPossible$.next(true)

    const k = new Kalpylator(this.propertyMap[domain])

    // Make copies to avoid trashing the originals...
    k.set<Applicant>(this.applicants$.value.map((a: Applicant) => Object.assign({}, a)))
    k.set<Income>(this.incomes$.value.map((i: Income) => Object.assign({}, i)))
    k.set<Property>(this.properties$.value.map((p: Property) => Object.assign({}, p)))
    k.set<Child>(this.children$.value.map((c: Child) => Object.assign({}, c)))
    k.set<Loan>(this.loans$.value.map((l: Loan) => Object.assign({}, l)))
    k.set<Car>(this.cars$.value.map((c: Car) => Object.assign({}, c)))
    return k
  }

  public propertyDefaults(property: Property): Property {
    const defaults = this.propertyMap.borgo
    property.runCost = defaults.propertyDefaults[property.propertyType].runCost
    return property
  }

  public reset(): void {
    this.inputs.forEach((s: Subject<any>) => s.next([]))
    this.pCalculationsPossible$.next(false)
    this.lastStep = 0
  }
}
