<div class="middle-holder">
  <div class="middle">
    <div (click)="reset()" [routerLink]="[startPath]" class="logo">
      Borgo Kalpylator
    </div>

    <div *ngIf="configService.configState$ | async as config" class="middle">
      <div class="spb-filler"></div>
      <div *ngIf="this.configService.currentUser$ | async as user">
        {{user.name}}
      </div>
      <div class="middle-menu">
        <div *ngIf="config.ready">
          <button [matMenuTriggerFor]="menu" mat-button>
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button [routerLink]="[startPath]" mat-menu-item>Kalpylator</button>
            <div *ngIf="config.bkAdmin">
              <button [routerLink]="[adminPath]" mat-menu-item>Admin tjänster</button>
            </div>
            <button (click)="logout()" mat-menu-item>Logga ut</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
