import {Injectable, NgZone} from '@angular/core'
import {
  CanActivate,
  CanLoad,
  Router,
  UrlTree,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment
} from '@angular/router'
import {Observable} from 'rxjs'
import {ConfigService, SpbConfiguration} from '../services/config.service'
import {LOGIN_ROUTE_PATH} from './data-types'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  private config: SpbConfiguration = {} as any

  constructor(
    private configService: ConfigService,
    private router: Router,
    private ngZone: NgZone
  ) {

    this.configService.configState$.subscribe({
      next: (config: SpbConfiguration) => this.config = config
    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.test(route.url.join('/'))
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.test(route.path + '')
  }

  private test(route: string): boolean | Promise<boolean> {
    if (route.indexOf('admin') !== -1) {
      return this.config.bkAdmin
    }
    return this.config.bkUser || this.config.bkAdmin || this.ngZone.run(() => this.router.navigate(['/', LOGIN_ROUTE_PATH])).then()
  }
}
