import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest, HttpEvent} from '@angular/common/http'
import {ConfigService} from '../services/config.service'
import {Observable} from 'rxjs'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * @param configService - Knows if we have a token or not.
   */
  constructor(private configService: ConfigService) {
  }

  /**
   * Intercept all requests and add auth token. We should however
   * do this only on white listed requests so that we do not
   * send our tokens when not needed.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.configService.getAccessToken()

    const headers = req.headers

    /**
     * Only send authorization bearer if endpoint is relevant
     */
    if (req.url.indexOf('service') !== -1 || req.url.indexOf('/api') !== -1) {
      const authReq = req.clone({
        headers: headers.set('Authorization', `Bearer ${token}`),
      })
      return next.handle(authReq)
    }

    /**
     * Else set no more headers
     */
    return next.handle(req)
  }
}
