import {Component, NgZone, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService} from '../../services/config.service'
import {BorgoService} from '../../services/borgo.service'
import {START_ROUTE_PATH, ADMIN_ROUTE_PATH} from '../../application/data-types'
import {KalpService} from '../../services/kalp.service'
import {environment} from '../../../environments/environment'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {catchError, EMPTY} from 'rxjs'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public startPath = '/' + START_ROUTE_PATH

  public adminPath = '/' + ADMIN_ROUTE_PATH

  private bankIdUrl = environment.authServiceUrl

  constructor(
    public configService: ConfigService,
    private borgoService: BorgoService,
    private kalpService: KalpService,
    private ssoService: SingleSignOnService,
    private router: Router,
    private ngZone: NgZone
  ) {
  }

  public ngOnInit(): void {
    this.ssoService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          this.logout()
          return EMPTY
        })
      )
      .subscribe(
        {next: (accessToken: string) => this.configService.setToken(accessToken)})
  }

  public reset(): void {
    this.borgoService.reset()
    this.kalpService.reset()
  }

  public logout(): void {
    this.configService.resetToken()
    this.ssoService.deleteToken(this.bankIdUrl)
      .subscribe({
        next: () => {
          this.configService.resetToken()
          return this.ngZone.run(() => this.router.navigate(['login']))
        }
      })
  }
}
