import {APP_INITIALIZER, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {SpbCommonModule} from './common/common.module'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AuthInterceptor} from './application/auth-interceptor'
import {ConfigService} from './services/config.service'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import '@angular/common/locales/global/fr'
import '@angular/common/locales/global/sv'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatNativeDateModule} from '@angular/material/core'
import {MAT_DATE_LOCALE} from '@angular/material/core'

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SpbCommonModule,
    HttpClientModule,
    ThemeModule,
    MatNativeDateModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    ConfigService,
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => configService.checkStatus,
      deps: [ConfigService],
      multi: true,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
